import * as React from "react";
import { styledDialog } from "./ExpressTermsOfServiceModal.module.scss";
import Modal from "react-bootstrap/Modal";

export const StyledDialog = (props: any) => {
    return (
        <Modal
            size="lg"
            show={props.show}
            onHide={props.onHide}
            centered
            scrollable={true}
            aria-labelledby="privacy-policy-modal"
            className={styledDialog}
        >
            {props.children}
        </Modal>
    );
};

