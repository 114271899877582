import React, { useState, useEffect } from "react";
import Layout from "../components/global/Layout/Layout";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import SEO from "../components/global/SEO/SEO";
import SuccessModal from "../components/common/SuccessModal/SuccessModal";
import Container from "react-bootstrap/Container";
import {
  StyledContainer,
  Disclaimer,
  Note,
} from "../pageHelpers/ExpressEnrollment/ExpressEnrollmentHelpers";
import Title5 from "../components/global/Title5/Title5";
import Title2 from "../components/global/Title2/Title2";
import SubTitle from "../components/global/SubTitle/SubTitle";
import { updateGuestSMSStatus } from "../../src/services/support";
import ExpressTermsOfServiceModal from "../components/common/ExpressTermsOfServiceModal/ExpressTermsOfServiceModal";

const ExpressEnrollmentPage = ({ location }: any) => {
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));

  const { register, handleSubmit, errors, setValue, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const required = "This field is required.";
  const [validated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const [smsOpted, setSmsOpted] = useState(false);

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const handleTermsModalClose = () => {
    setShowTermsModal(false);
  };

  const handleTermsModalShow = () => {
    setShowTermsModal(true);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    setSmsOpted(data.Optin);
    const response = await updateGuestSMSStatus(data);

    if (!response || response.error) {
      setErrorMessage(`An error occurred while updating.
        Please try again.`);
    } else {
      setShowSuccess(true);
      reset();
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    setValue("Firstname", queryParams.fname);
    setValue("Lastname", queryParams.lname);
    setValue("Emailid", queryParams.email);
    setValue("Mobilenumber", queryParams.mobile || "");
    setValue("Confirmationnumber", queryParams.conf || "");
    setValue("Optin", 0);
  }, []);

  return (
    <Layout>
      <SEO title={"Check In Made Easy"} noIndex={true} noFollow={true} />
      <Container>
        <StyledContainer className="pt-5 pb-5" fluid="sm">
          <Row>
            <Col lg={4} className="aside">
              <SubTitle></SubTitle>
              <Title2>Check In Made Easy</Title2>
            </Col>
            <Col lg={{ offset: 1 }}>
              <h5>Want to check in faster?</h5>
              <p>
                Complete the below form to receive notifications regarding your
                upcoming stay and check in prior to your arrival.
              </p>
              {errorMessage && (
                <Alert variant={`danger`}> {errorMessage}</Alert>
              )}
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Form.Group as={Col} lg={6} controlId="Firstname">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="Firstname"
                      ref={register({
                        required: required,
                      })}
                      isInvalid={errors.Firstname}
                      aria-invalid={errors.Firstname ? "true" : "false"}
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.Firstname && errors.Firstname.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg={6} controlId="Lastname">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="Lastname"
                      ref={register({
                        required: required,
                      })}
                      isInvalid={errors.Lastname}
                      aria-invalid={errors.Lastname ? "true" : "false"}
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.Lastname && errors.Lastname.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} lg={6} controlId="Confirmationnumber">
                    <Form.Label>Reservation/Confirmation #*</Form.Label>
                    <Form.Control
                      type="text"
                      name="Confirmationnumber"
                      ref={register({
                        required: required,
                      })}
                      isInvalid={errors.Confirmationnumber}
                      aria-invalid={
                        errors.Confirmationnumber ? "true" : "false"
                      }
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.Confirmationnumber &&
                        errors.Confirmationnumber.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg={6} controlId="Mobilenumber">
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control
                      type="text"
                      name="Mobilenumber"
                      ref={register({
                        required: required,
                      })}
                      isInvalid={errors.Mobilenumber}
                      aria-invalid={errors.Mobilenumber ? "true" : "false"}
                      maxLength="15"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.Mobilenumber && errors.Mobilenumber.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} lg={6} controlId="Emailid">
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      type="email"
                      name="Emailid"
                      ref={register({
                        required: required,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                          message: "A valid Email address is required.",
                        },
                      })}
                      isInvalid={errors.Emailid}
                      aria-invalid={errors.Emailid ? "true" : "false"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.Emailid && errors.Emailid.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="Optin">
                    <Form.Check
                      label="Yes, I would like to opt in to receive SMS/text messages regarding my upcoming stay. (1)(2)"
                      name="Optin"
                      ref={register}
                    />
                  </Form.Group>
                </Row>
                <Note>
                  <p>
                    1. By selecting the opt in checkbox above you are agreeing
                    to receive SMS/text message notifications regarding your
                    room reservation from Red Lion Corporation branded hotels.
                    If you would like to stop receiving notifications via
                    SMS/text you can reply to the message with STOP at any time.
                  </p>
                  <p>
                    2. By leaving the checkbox above unchecked you are advising
                    that you do not want to receive SMS/text message
                    notifications regarding your upcoming stay from Red Lion
                    Corporation branded hotels.
                  </p>
                </Note>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="text-uppercase w-100 my-3"
                >
                  {isSubmitting ? `Please wait...` : `Submit`}
                </Button>
                <Disclaimer className="mt-5 d-block">
                  By submitting this form I certify that I have read,
                  understand, and accept the
                  <Button
                    type="button"
                    variant="link"
                    className="m-0 p-0"
                    onClick={handleTermsModalShow}
                    tabIndex={0}
                  >
                    {" "}
                    Terms of Service.{" "}
                  </Button>
                  {/* <button type="button" className="btn btn-link m-0 p-0">Terms of Service. </button> */}
                </Disclaimer>
              </Form>
            </Col>
          </Row>
          <ExpressTermsOfServiceModal
            show={showTermsModal}
            onHide={handleTermsModalClose}
          />

          <SuccessModal show={showSuccess} onHide={handleSuccessClose}>
            <SubTitle className="text-center mb-1">CONFIRMATION</SubTitle>
            {smsOpted ? (
              <>
                <Title5 className="m-3">
                  Congratulations{" "}
                  <span className="text-capitalize">{queryParams.fname}</span>{" "}
                  You are enrolled in Express Check In / Check Out.
                </Title5>
                <p className="p-2">
                  On the day of arrival, you will receive a text message with a
                  link that will allow you to check in remotely prior to your
                  arrival at the hotel.
                </p>
                <p className="p-2">
                  <span className="text-capitalize font-weight-bold">
                    {queryParams.property || "Hotel"}
                  </span>{" "}
                  is looking forward to your stay.
                </p>
              </>
            ) : (
              <>
                <Title5 className="m-3">
                  <span className="text-capitalize">{queryParams.fname}</span>,
                  you have opted out of receiving SMS text messages from RLH
                  Corporation branded hotels.
                </Title5>
                <p className="p-2">
                  {" "}
                  You will not be able to check in remotely prior to arrival.
                  Please visit the hotel&apos;s front desk on the date of your
                  arrival to check in.
                </p>
                <p className="p-2">
                  <small>
                    <i>
                      To check in remotely you must be opted into SMS text
                      communications. You can opt in by checking the box on this
                      page.
                    </i>
                  </small>
                </p>
              </>
            )}
          </SuccessModal>
        </StyledContainer>
      </Container>
    </Layout>
  );
};
export default ExpressEnrollmentPage;
