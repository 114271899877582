import * as React from "react";
import { styledContainer, disclaimer, note } from "./ExpressEnrollment.module.scss";

export const StyledContainer = (props: any) => {
    return (<div className={styledContainer}>{props.children}</div>);
};
export const Disclaimer = (props: any) => {
    return (<div className={disclaimer}>{props.children}</div>);
};
export const Note = (props: any) => {
    return (<div className={note}>{props.children}</div>);
};
