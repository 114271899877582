import React from "react";
import { IExpressTermsOfServiceModalProps } from "./ExpressTermsOfServiceModalProps";
import Modal from "react-bootstrap/Modal";
import { useStaticQuery, graphql } from "gatsby";
import SubTitle from "../../global/SubTitle/SubTitle";
import Title5 from "../../global/Title5/Title5";
import { StyledDialog } from "./ExpressTermsOfServiceModalHelpers";

const ExpressTermsOfServiceModal = (props: IExpressTermsOfServiceModalProps) => {

    const data = useStaticQuery(graphql`
    {
      allNodePage(filter: {path: {alias: {eq: "/express-terms-service"}}}) {
        edges {
          node {
            title
            path {
              alias
            }
            body {
              processed
              value
            }
          }
        }
      }
    }
  `);

    let terms = null;
    if (data.allNodePage.edges.length) {
        terms = data.allNodePage.edges[0].node;
    }

    return (
        <StyledDialog
            show={props.show}
            onHide={props.onHide}
            aria-label="Terms of Service"
        >
            <Modal.Header closeButton>
                <Modal.Title id="express-termsofservice-modal">
                    <Title5>Express Check In / Check Out</Title5>
                    <SubTitle>Short Message Service (SMS) Terms & Conditions</SubTitle>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {terms &&
                    <div dangerouslySetInnerHTML={{ __html: terms.body.processed }} />
                }
            </Modal.Body>
        </StyledDialog>
    );

};

export default ExpressTermsOfServiceModal;