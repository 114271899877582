// extracted by mini-css-extract-plugin
export var alt = "ExpressEnrollment-module--alt--9cb64";
export var btnLink = "ExpressEnrollment-module--btn-link--8e76c";
export var desktopOnly = "ExpressEnrollment-module--desktopOnly--37a9d";
export var disclaimer = "ExpressEnrollment-module--disclaimer--f8c84";
export var h5 = "ExpressEnrollment-module--h5--196ab";
export var main = "ExpressEnrollment-module--main--d1e3e";
export var mobileOnly = "ExpressEnrollment-module--mobileOnly--95a1f";
export var note = "ExpressEnrollment-module--note--3cebf";
export var styledContainer = "ExpressEnrollment-module--styledContainer--3f1be";
export var textLeft = "ExpressEnrollment-module--textLeft--41536";
export var visuallyHidden = "ExpressEnrollment-module--visuallyHidden--771d6";